import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import _ from 'lodash'

// utils
import catResolve from '../../utils/catResolve'

const FooterCats = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { fileAbsolutePath: { regex: "/blog/" } }) {
          group(field: rawBody) {
            nodes {
              frontmatter {
                category
              }
            }
          }
        }
      }
    `,
  )
  const cats = data.allMdx.group
  let compiledArray = []

  cats.forEach((cat, i) => {
    const postCategories = cat.nodes[0].frontmatter.category
    postCategories.forEach(cat => {
      compiledArray.push(cat)
    })
  })

  const catCounts = _.countBy(compiledArray)

  return (
    <div>
      <div className="catGroup">
        <ul className="list-inline text-center">
          {Object.keys(catCounts).map(key => {
            return (
              <React.Fragment key={key}>
                <li key={key} className="list-inline-item">
                  <Link to={`/category/${catResolve(key)}`}>
                    <span className="category">
                      <span className="catCount">{catCounts[key]}</span>
                      {catResolve(key, true)}
                    </span>
                  </Link>
                </li>
              </React.Fragment>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default FooterCats
