import React from 'react'
import { Link } from 'gatsby'

import RightClick from './RightClick'

const Logo = () => {
  return (
    <>
      <RightClick>
        <Link to="/" className="navbar-brand theLogo">
          <svg id="svgLogo" version="1.1" x="0" y="0" viewBox="0 0 500 500">
            <g>
              <path
                d="M455,203c-8.8-0.2-15.7,2.9-21.1,7.5c-0.2-0.7-0.5-1.4-0.7-2.1c-20.6-58.1-65.1-93.7-102.7-115
                    c11.6-13.6,10-28,10-28s-7.5,3.7-26.1,3.6c14.2-15.3,2.1-38.1,2.1-38.1s-9.6,8.6-29,15.8c4.7-22.9-4.2-43.8-4.2-43.8
                    s1.9,26.8-50.7,50.5c1.2-28.2-6.5-33.9-6.5-33.9S208.3,52.8,184.4,73c-11.1,3.8-22.6,8.8-33.9,15.3c-0.4,0-0.8,0-1.1,0
                    c0.2,0.1,0.4,0.2,0.6,0.3c-36.3,21.2-70.8,58.3-86.7,121.5c-0.2,0.6-0.3,1.3-0.5,1.9c-5.2-5.1-11.9-8.6-20.6-8.4
                    c-30.6,0.8-48.4,79.5-29.4,124.7c14.5,34.4,37.8,55.3,59.2,47.8c1,1.9,1.9,3.8,3,5.7c44.3,82.2,146.9,115.9,183.8,115.1
                    c36.3-0.8,123.6-27.8,164.4-115.2c1-2.1,2-4.3,2.9-6.5c22.1,4.8,45.7-15.6,60.5-48.3C507,282,487.9,203.8,455,203z M396,338
                    c-0.3,0.7-0.5,1.4-0.8,2c-8.9,21.3-23.5,37.6-43.6,39.5c0,0-3.3,0.2-3.4,0.2c0,0,20.9,104.3-85.6,107.3
                    c-105.2,3-104.6-60.7-84.5-110.1c0,0-3.7,0-3.8,0c-8.2,0.4-28.3,0.5-41.2-8.4C95.5,342.5,95,262,115.8,237.7
                    c20.9-24.3,97.9-6.8,137.5-11.9c39.7-5.1,110.6-27.1,138.8,7.3c7.8,9.5,12.2,24.9,13.4,42.2c0.2,3,0.3,6,0.3,9.1
                    C405.9,302.5,402.6,321.6,396,338z"
              />
            </g>
          </svg>
        </Link>
      </RightClick>
    </>
  )
}

export default Logo
