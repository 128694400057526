import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// React Icons
import { FaCode, FaHeart } from 'react-icons/fa'
import { SiGatsby } from 'react-icons/si'

// Components
import ExternalLink from './ExternalLink'

const Copyright = () => {
  const year = new Date()

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  )

  return (
    <>
      <p className="copyright">
        Copyright &#169; {year.getFullYear()} <Link to="/">{data.site.siteMetadata.title}</Link>.
        All Rights Reserved.
        <br />
        <span className="soft">
          <FaCode />
        </span>{' '}
        with{' '}
        <span className="soft">
          <FaHeart />
        </span>{' '}
        using{' '}
        <ExternalLink href="https://www.gatsbyjs.com/" title="Gatsby JS">
          <SiGatsby className="soft" />
        </ExternalLink>
      </p>
    </>
  )
}

export default Copyright
