import React from 'react'
import { navigate } from 'gatsby'

const RightClick = ({ children, ...props }) => {
  const click = e => {
    e.preventDefault()
    return navigate(`/testing/`)
  }

  return (
    <>
      <div onContextMenu={click} aria-hidden="true">
        {children}
      </div>
    </>
  )
}

export default RightClick
