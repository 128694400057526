import React from 'react'

// Components
import FooterCats from './FooterCats'
import Coffee from './Coffee'
import Copyright from './Copyright'

const Footer = () => {
  return (
    <>
      <Coffee />
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <FooterCats />
              <Copyright />
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
