import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Navbar from './Navbar'
import Footer from './Footer'

const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              name
              href
            }
          }
        }
      }
    `,
  )

  const menu = data.site.siteMetadata.menuLinks

  return (
    <>
      <Navbar menu={menu} />
      {children}
      <Footer />
    </>
  )
}

export default Layout
