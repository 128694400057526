import titleCase from './titleCase'

const nameFormat = cat => {
  const c = lowercase(cat)

  switch (c) {
    case 'sips':
      return 'SIPs'
    case 'applescript':
      return 'AppleScript'
    case 'regex':
      return 'REGEX'
    case 'sed':
      return 'SED'
    case 'imagemagick':
      return 'ImageMagick'
    default:
      return titleCase(c)
  }
}

const lowercase = cat => {
  return cat.toString().toLowerCase()
}

const convertor = (cat, title = false) => {
  return title === true ? nameFormat(cat) : lowercase(cat)
}

export default convertor
