import React from 'react'

import image from '../../assets/buymeacoffee.svg'
import ExternalLink from '../components/ExternalLink'

const Coffee = () => {
  return (
    <>
      <div className="coffee">
        <div className="coffeeText">Help the site go ad free:</div>
        <div className="coffeeImage">
          <ExternalLink title="Buy Me a Coffee" href="https://www.buymeacoffee.com/codingChewie">
            <img className="img-fluid" src={image} alt="Storm Trooper" />
          </ExternalLink>
        </div>
      </div>
    </>
  )
}

export default Coffee
