import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

// Components
import Logo from './Logo'

// React Icons
import { FaBars, FaSearch } from 'react-icons/fa'

const Navbar = ({ menu }) => {
  const [mobile, setMobile] = useState(true)
  const showMobile = () => (window.innerWidth <= 992 ? setMobile(false) : setMobile(true))
  useEffect(() => showMobile(), [])
  if (typeof window !== 'undefined') window.addEventListener('resize', showMobile)

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
        <Logo />
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
          <FaBars style={{ marginLeft: '4px' }} />
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              {mobile === true ? (
                <Link className="nav-link" to="/">
                  Home
                </Link>
              ) : (
                <Link className="nav-link" to="/search">
                  Search
                </Link>
              )}
            </li>
            {menu.map((item, key) => {
              return (
                <li key={key} className="nav-item">
                  <Link className="nav-link" to={`${item.href}`}>
                    {item.name}
                  </Link>
                </li>
              )
            })}
            {mobile && (
              <li className="nav-item">
                <Link className="nav-link" to="/search">
                  <FaSearch />
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Navbar
